<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div v-if="this.loggedin && typeof this.loggedin != undefined" class="mt-[20px] sm:mt-[45px] text-left sm:px-6 lg:px-8 mx-auto w-[90vw] max-w-[800px]">
    <div class="flex flex-col md:items-stretch justify-center p-4 bg-white border border-gray-200 rounded-lg">
      <div class="flex flex-col-reverse md:flex-row md:items-start justify-between">
        <h1 class="text-xl font-semibold text-gray-900 flex justify-between items-start md:justify-start"><div><img class="w-[155px] block" alt="Travelshop" src="https://development-travelshop-theme-de.pressmind.dev/wp-content/themes/travelshop/assets/img/travelshop-logo.svg" /> Import Manager</div> <span class="whitespace-nowrap inline-flex ml-3 mt-[3px] items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800"> {{ this.userList.length }} {{ this.userList.length == 1 ? 'user' : 'users' }} online </span></h1>
        <div class="mt-0 mb-2 md:ml-16 md:my-0 flex items-center justify-between">
          <span class="text-lg mr-5 flex font-bold text-[#0294FE]"><img class="w-[125px]" src="@/assets/logo.svg" alt="Pressmind" /><span class="ml-1 md:translate-y-[-5px]">®</span></span>
          <button @click="this.signOut()" type="button" class="inline-flex items-center justify-center rounded-md border border-transparent bg-[#95a5a6] hover:bg-[#7f8c8d] px-2 py-1 text-sm font-medium text-white shadow-sm focus:outline-none sm:w-auto">Logout</button>
        </div>
      </div>
      <div class="flex flex-row items-center justify-between">
        <p class="mt-2 text-sm text-gray-700">A lists of websites, where imports are possible.<br class="hidden sm:block" /> <strong>Last user who started a process:</strong> <span v-if="this.historyLoaded">{{ this.history[this.history.length - 1]?.person }}</span></p>
        <div class="inline-flex justify-end relative">
          <div class="hidden md:block text-sm text-right truncate max-h-10">
            <p class="font-bold"> {{this.userData.firstname}} {{this.userData.surname}} </p>
            <p class="text-[.7rem]"> {{this.userData.email}} </p>
          </div>
          <span @click="this.userListMenu = !this.userListMenu" class="cursor-pointer inline-block relative h-10 w-10 ml-3">
            <img class="h-10 w-10 rounded-md shadow-md" :src="this.userData.image" alt="" />
            <span class="absolute bottom-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white bg-green-400" />
          </span>
          <div v-if="false" class="absolute inline-flex top-[125%] right-0 bg-white border border-gray-200 shadow-md z-30 rounded-lg">
            <ul>
              <li class="px-4 py-2 flex justify-between items-center" v-for="(user, userIdx) in this.userList" :id="'tus' + userIdx" :key="userIdx">
                <span class="text-xs">{{ user.firstname }} {{ user.surname }}</span> 
                <span class="inline-flex">
                  <svg xmlns="http://www.w3.org/2000/svg" class="hidden mr-1 icon icon-tabler icon-tabler-message-2" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#597e8d" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M12 20l-3 -3h-2a3 3 0 0 1 -3 -3v-6a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-2l-3 3" />
                    <line x1="8" y1="9" x2="16" y2="9" />
                    <line x1="8" y1="13" x2="14" y2="13" />
                  </svg> 
                  <span v-if="user.online" class="inline-flex items-center ml-2 px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"> online </span>
                  <span v-if="!user.online" class="inline-flex items-center ml-2 px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"> offline </span>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-between mt-3">
      <div class="grow">
        <div>
          <ul role="list" class="relative grid grid-cols-1 gap-1.5 md:gap-3 md:grid-cols-3">
            <li class="col-span-1 flex rounded-md cursor-pointer">
              <div :class="['bg-[#DB2777] flex-shrink-0 flex items-center justify-center w-2 text-white text-sm font-medium rounded-l-md']">
              </div>
              <div class="relative flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md">
                <div class="flex-1 pl-3 pr-0 py-1 text-xs">
                  <a href="#" class="text-gray-900 font-medium hover:text-gray-600">Process Blocker</a>
                  <p class="text-gray-500">Place a blocker for a travelshop</p>
                </div>
                <div class="flex-shrink-0 pr-2">
                  <button @click="this.blockerMenu = !this.blockerMenu" type="button" class="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span class="sr-only">Open options</span>
                    <DotsVerticalIcon class="w-4 h-4 focus:ring-0" aria-hidden="true" />
                  </button>
                </div>
                <div v-if="this.blockerMenu" class="absolute z-30 right-[5px] top-[100%] border border-gray-200 shadow-lg left-0 bg-white text-xs">
                  <ul class="py-1">
                    <li @click="site.blocked == 'true' ? site.blocked = 'false' : site.blocked = 'true'; site.blocker = this.userData.firstname + ' ' + this.userData.surname; this.updateSite(site);" class="px-4 py-1 hover:bg-gray-100 cursor-pointer flex items-center" v-for="(site, siteIdx) in this.sites" :id="'tx' + siteIdx" :key="siteIdx">
                      <span class="mr-3 w-[12px] h-[12px] relative rounded-full border border-gray-400 bg-[#efefef] inline-flex items-center justify-center">
                        <span :class="{ 'inline-block' : site.blocked  == 'true'}" class="hidden w-[8px] h-[8px] rounded-full bg-[#DB2777]"></span>
                      </span> {{ this.renderPath(site.path)[1] }}
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="col-span-1 flex rounded-md cursor-pointer">
              <div :class="['bg-[#badc58] flex-shrink-0 flex items-center justify-center w-2 text-white text-sm font-medium rounded-l-md']">
              </div>
              <div class="relative flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md">
                <div class="flex-1 pl-3 pr-0 py-1 text-xs">
                  <a href="#" class="text-gray-900 font-medium hover:text-gray-600">Process History</a>
                  <p class="text-gray-500">Look at the latest processes</p>
                </div>
                <div class="flex-shrink-0 pr-2">
                  <button @click="this.historyMenu = !this.historyMenu" type="button" class="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span class="sr-only">Open options</span>
                    <DotsVerticalIcon class="w-4 h-4 focus:ring-0" aria-hidden="true" />
                  </button>
                </div>
                <div v-if="this.historyMenu" class="absolute z-30 right-[5px] top-[100%] border border-gray-200 shadow-lg left-0 bg-white">
                  <ul class="py-1">
                    <li class="px-4 py-2 leading-[1]" v-for="(element, elemIdx) in this.history.slice(-4).reverse()" :id="'tv' + elemIdx" :key="elemIdx">
                      <span class="text-xs">{{ new Date(parseInt(element.timestamp)).getDate() }}.{{ new Date(parseInt(element.timestamp)).getMonth() + 1 }} / {{ new Date(parseInt(element.timestamp)).getHours() <= 9 ? '0' + new Date(parseInt(element.timestamp)).getHours() : new Date(parseInt(element.timestamp)).getHours() }}:{{ new Date(parseInt(element.timestamp)).getMinutes() <= 9 ? '0' + new Date(parseInt(element.timestamp)).getMinutes() : new Date(parseInt(element.timestamp)).getMinutes() }} - {{ element.person }}</span><br />
                      <strong class="text-xs">{{ element.action }}</strong> <span class="text-xs"> <span v-if="element.finished && element.totaltime" class="inline-flex items-center px-2 py-0 mt-1 rounded-full text-xs font-medium bg-blue-100 text-blue-800"> finished </span> <span v-if="!element.finished && element.totaltime" class="inline-flex items-center px-2 py-0 mt-1 rounded-full text-xs font-medium bg-red-100 text-red-800"> interrupted </span> <span v-if="!element.totaltime" class="inline-flex items-center px-2 py-0 mt-1 rounded-full text-xs font-medium bg-green-100 text-green-800"> running </span></span><br />
                      <span class="text-xs"> {{ this.getSiteName(element) }} </span><br />
                      <span class="text-xs"> Total time: {{ element.totaltime ? element.totaltime : this.processTime }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="col-span-1 relative flex rounded-md cursor-pointer">
              <div class="absolute w-full h-full top-0 left-0 bg-[rgba(255,255,255,.7)] z-10"></div>
              <div :class="['bg-green-500 flex-shrink-0 flex items-center justify-center w-2 text-white text-sm font-medium rounded-l-md']">
              </div>
              <div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                <div class="flex-1 pl-3 pr-0 py-1 text-xs">
                  <a href="#" class="text-gray-900 font-medium hover:text-gray-600">AMIS Settings</a>
                  <p class="text-gray-500">Settings regarding AMIS</p>
                </div>
                <div class="flex-shrink-0 pr-2">
                  <button type="button" class="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span class="sr-only">Open options</span>
                    <DotsVerticalIcon class="w-4 h-4 focus:ring-0" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="mt-3 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-100 border-b-2 border-[#888]">
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Installation path
                    <span v-if="this.process.running" @click="this.open = true" class="cursor-pointer inline-flex ml-1 items-center px-2.5 py-[0.1rem] rounded-full text-xs font-medium bg-green-100 text-green-800"> {{ this.process.action }} running <div v-if="this.process.running" class="lds2-ring lds-ring inline-flex items-center ml-1"><div></div><div></div><div></div><div></div></div></span>
                  </th>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    ID
                  </th>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Server
                  </th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 text-sm sm:pr-6 text-right">
                    <input type="text" v-model="this.searchTerm" @keyup.enter="this.findShop()" placeholder="search and enter" class="hidden md:inline text-sm font-light px-2.5 py-0.5 rounded-md border-0 shadow-md focus:ring-0" />
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white">
                <tr v-for="(site, siteIdx) in this.sites" :id="'ts' + siteIdx" :key="siteIdx" class="tablerow relative overflow-hidden" style="transform: scale(1);" :class="{ 'bg-gray-50' : siteIdx % 2 === 0, 'bg-[#fff7d8]' : site.blocked == 'true', 'bg-[#eeffe2]' : site.path == this.process.path && this.process.running }">
                  <td :class="{'pt-[45px]' : site.blocked == 'true'}" class="h-[45px] whitespace-nowrap relative py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    <div :class="{ 'min-h-[88px]' : site.blocked == 'true', 'flex': this.visible == site.hostingid }" class="bg-gray-200 w-[736px] absolute z-40 h-[45px] top-0 left-0 right-0 p-3 px-[1.5rem] text-xs items-center justify-between shadow-sm hidden">
                      <div class="inline-flex items-center">
                        <a target="_blank" :href="this.currentWPURL">
                          <img class="w-[125px]" src="https://upload.wikimedia.org/wikipedia/commons/2/20/WordPress_logo.svg" alt="WordPress" />
                        </a>
                        <span class="ml-[1.5rem] inline-flex items-center translate-y-[1px]">
                          User: <span :ref="'wpuser' + site.hostingid" class="ml-1"> {{ this.currentWPLogin.user }} </span>
                          <svg v-if="this.$refs['wpuser' + site.hostingid]" @click="this.selectText(this.$refs['wpuser' + site.hostingid][0]);" xmlns="http://www.w3.org/2000/svg" class="ml-1 icon icon-tabler icon-tabler-copy" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <rect x="8" y="8" width="12" height="12" rx="2" />
                            <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
                          </svg>
                        </span>
                        <span class="ml-[1.5rem] inline-flex items-center translate-y-[1px]">
                          Password: <span :ref="'wppw' + site.hostingid" class="ml-1"> {{ this.currentWPLogin.password }} </span>
                          <svg v-if="this.$refs['wppw' + site.hostingid]" @click="this.selectText(this.$refs['wppw' + site.hostingid][0]);" xmlns="http://www.w3.org/2000/svg" class="ml-1 icon icon-tabler icon-tabler-copy" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <rect x="8" y="8" width="12" height="12" rx="2" />
                            <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
                          </svg>
                        </span>
                        <span v-if="this.copiedText" class="ml-[1.5rem] inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">...copied!</span>
                      </div>
                      <svg @click.stop="this.visible = 'x'" xmlns="http://www.w3.org/2000/svg" class="ml-3 cursor-pointer icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <line x1="18" y1="6" x2="6" y2="18" />
                        <line x1="6" y1="6" x2="18" y2="18" />
                      </svg>
                    </div>
                    <div v-if="site.blocked == 'true'" class="absolute z-30 top-0 left-0 right-0 w-[736px] font-black min-w-[500px] rounded-tr-md rounded-br-md shadow-sm bg-[#ffeeb2] px-5 pb-[3px] pt-[4px] text-amber-700 text-[.65rem]">
                      This travelshop is blocked by {{ site.blocker }}. Ask for permission.</div>
                    {{ this.renderPath(site.path)[0] }}
                  </td>
                  <td :class="{'pt-[45px]' : site.blocked == 'true'}" class="h-[45px] whitespace-nowrap cursor-pointer py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-2">
                    <div @click="this.getHostingData(site.hostingid); this.visible = site.hostingid" class="inline-flex items-center px-[6px] py-[2px] text-xs border border-transparent text-[.8rem] font-medium rounded shadow-sm text-white bg-[#706fd3] hover:bg-[#474787] focus:outline-none">
                      {{ site.hostingid }}
                    </div>
                  </td>
                  <td :class="{'pt-[45px]' : site.blocked == 'true'}" class="h-[45px] whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{ site.ip }}</td>
                  <td :class="{'pt-[36px]' : site.blocked == 'true'}" class="h-[45px] relative whitespace-nowrap py-1 pl-3 pr-4 text-right text-xs font-medium sm:pr-6">
                    <div v-if="site.blocked != 'true' && !this.process.running" @click="this.startProcess(site.path, 'php import.php', 'fullimport', '', site.ip, site.hostingid); site.process = true; this.process.path = site.path" class="cursor-pointer inline-flex items-center px-[6px] py-[2px] border border-transparent text-[.8rem] font-medium rounded shadow-sm text-white bg-[#0294FE] hover:bg-[#047dd3] focus:outline-none">import</div>
                    <div v-if="false && site.blocked != 'true' && !this.process.running" @click="this.startProcess(site.path, 'php install.php', '', '', site.ip, site.hostingid); site.process = true; this.process.path = site.path" class="cursor-pointer inline-flex items-center px-[6px] py-[2px] border border-transparent text-[.8rem] font-medium rounded shadow-sm ml-2 text-white bg-[#1abc9c] hover:bg-[#16a085] focus:outline-none">install</div>
                    <div v-if="false && site.blocked != 'true' && !this.process.running" @click="this.startProcess(site.path, 'php reset.php', '', 'yes', site.ip, site.hostingid); site.process = true; this.process.path = site.path" class="cursor-pointer inline-flex items-center px-[6px] py-[2px] border border-transparent text-[.8rem] font-medium rounded shadow-sm ml-2 text-white bg-[#95a5a6] hover:bg-[#7f8c8d] focus:outline-none">reset</div>
                    <div @click="this.deleteSite(site.hostingid)" v-if="false && site.blocked != 'true' && !this.process.running" class="cursor-pointer inline-flex items-center ml-2 px-[6px] py-[2px] border border-transparent text-[.8rem] font-medium rounded shadow-sm text-white bg-[#e74c3c] hover:bg-[#c0392b] focus:outline-none">delete</div>
                    <div v-if="site.blocked == 'true'" class="cursor-pointer inline-flex items-center ml-2 px-[6px] py-[2px] border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-[#DB2777] focus:outline-none">blocked</div>
                    <div v-if="this.process.running" class="text-[#ccc]">
                      <span v-if="site.path == this.process.path" @click="this.open = true" class="mr-3 cursor-pointer inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium border shadow-sm border-green-300 bg-green-100 text-green-800">{{ this.process.action }} running <div v-if="this.process.running" class="lds2-ring lds-ring inline-flex items-center ml-1"><div></div><div></div><div></div><div></div></div></span>
                      <span v-if="site.blocked != 'true'">no multi tasks</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TransitionRoot as="template" :show="this.open">
    <Dialog as="div" class="relative z-50">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden bg-[rgba(0,0,0,.15)]">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div class="flex h-full flex-col overflow-y-scroll bg-white" style="box-shadow: 0 0px 12px rgba(0,0,0,0.11), 0 0px 12px rgba(0,0,0,0.17);">
                  <div class="bg-[#0478cc] py-6 px-4 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white align-center flex items-center"> 
                      Current process 
                      <span v-if="this.process.running" class="inline-flex ml-3 items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"> {{ this.process.action }} </span>
                      <span v-if="!this.process.running" class="inline-flex ml-3 items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"> done </span>
                      <div v-if="this.process.running" class="lds-ring inline-flex items-center ml-1"><div></div><div></div><div></div><div></div></div>
                      </DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button type="button" class="rounded-md bg-[#fff] text-indigo-200 hover:text-white focus:outline-none" @click="this.open = false">
                          <span class="sr-only">Close panel</span>
                          <XIcon class="h-6 w-6 text-[#444]" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div v-if="this.process.path" class="mt-1">
                      <p class="text-sm text-indigo-300">{{ this.process.path }}</p>
                      <p class="text-sm text-indigo-300" >{{ 'Time: ' + this.processTime }}</p>
                      <div @click="this.cancelProcess()" v-if="this.process.running" class="mt-2 cursor-pointer inline-flex items-center px-[6px] pt-[2px] pb-[4px] border border-transparent text-xs font-medium rounded shadow-sm text-white bg-[#e74c3c] hover:bg-[#c0392b] focus:outline-none">suspend</div>
                    </div>
                  </div>
                  <div ref="logWindow" class="logwindow text-xs relative flex-1 py-6 px-4 sm:px-6">
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <div class="mt-3.5 mb-3 max-w-[800px] mx-auto">
      <div class="relative">
        <div class="absolute inset-0 h-1/2 bg-gray-50" />
        <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="max-w-4xl mx-auto">
            <dl class="rounded-lg bg-white sm:grid sm:grid-cols-3 border border-gray-200 flex justify-between">
              <div class="flex flex-col border-b border-gray-100 p-3 text-center sm:border-0 sm:border-r">
                <dt class="order-2 mt-2 text-sm leading-6 font-medium text-gray-500">total actions</dt>
                <dd class="order-1 text-2xl font-black text-[#0294FE]"> {{ this.total.processes }} </dd>
              </div>
              <div class="flex flex-col border-t border-b border-gray-100 p-3 text-center sm:border-0 sm:border-l sm:border-r">
                <dt class="order-2 mt-2 text-sm leading-6 font-medium text-gray-500">total logins</dt>
                <dd class="order-1 text-2xl font-black text-[#0294FE]">  {{ this.total.logins }}  </dd>
              </div>
              <div class="flex flex-col border-t border-gray-100 p-3 text-center sm:border-0 sm:border-l">
                <dt class="order-2 mt-2 text-sm leading-6 font-medium text-gray-500">total sites</dt>
                <dd class="order-1 text-2xl font-black text-[#0294FE]"> {{ this.sites.length }} </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <div class="max-w-[800px] mx-auto text-left mb-12 px-2 sm:px-6 lg:px-8">
      <div class="pt-4 pl-4 pr-4 pb-4 bg-white border border-gray-200 rounded-lg">
        <h1 class="text-xl font-semibold text-gray-900">Add Travelshop</h1>
        <p class="mt-2 mb-2 text-sm text-gray-700">Here you can, if you know the server path, deposit a travelshop</p>
        <form class="flex justify-between flex-col sm:flex-row">
          <div class="basis-[18.1666%] my-1">
            <div class="mt-1">
              <input type="text" autocomplete="off" v-model="this.newip" class="shadow-sm focus:ring-[#0294FE] focus:border-[#0294FE] block w-full sm:text-sm border-gray-300 px-4 rounded-lg" placeholder="server ip" />
            </div>
          </div>
          <div class="basis-[12.1666%] my-1">
            <div class="mt-1">
              <input type="text" autocomplete="off" v-model="this.newhostingid" class="shadow-sm focus:ring-[#0294FE] focus:border-[#0294FE] block w-full sm:text-sm border-gray-300 px-4 rounded-lg" placeholder="amis id" />
            </div>
          </div>
          <div class="basis-[38.5%] my-1">
            <div class="mt-1">
              <input type="text" autocomplete="off" v-model="this.newpath" class="shadow-sm focus:ring-[#0294FE] focus:border-[#0294FE] block w-full sm:text-sm border-gray-300 px-4 rounded-lg" placeholder="path e.g. /m-tours.de/development/" />
            </div>
          </div>
          <div class="basis-[14.1666%] my-1">
            <div class="mt-1">
              <input type="password" autocomplete="off" v-model="this.password" class="shadow-sm focus:ring-[#0294FE] focus:border-[#0294FE] block w-full sm:text-sm border-gray-300 px-4 rounded-lg" placeholder="password" />
            </div>
          </div>
          <div class="basis-[12%] my-1 flex justify-end align-end">
            <div class="mt-1 flex items-end grow">
              <div @click="this.createSite()" class="cursor-pointer flex justify-center grow text-center items-center px-1 py-2.5 border border-transparent text-sm leading-4 font-medium rounded-lg shadow-sm text-white bg-[#0294FE] hover:bg-[#047dd3]">create site</div>
            </div>
          </div>
        </form>
        <small v-if="this.message" class="mt-3 block text-red">{{ message }}</small>
      </div>
    </div>
</template>

<script>
import axios from 'axios';
import router from './router';
import io from 'socket.io-client';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'
import { DotsVerticalIcon } from '@heroicons/vue/solid'

var socket = io('/', {
  forceNew: false,
  withCredentials: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 7500,
  reconnection: true,
  timeout: 60000,
  reconnectionAttemps: Infinity,
  transports: ['polling', 'websocket'],
  secure: true,
  agent: false,
  upgrade: false,
  allowEIO3: true,
  rejectUnauthorized: true,
  closeOnBeforeunload: false,
  autoConnect: true
});

export default {
  name: 'App',
  components: {
    Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, XIcon, DotsVerticalIcon
  },
  data() {
    return {
      sites: [
      ],
      socket: '',
      open: false,
      newpath: '',
      newip: '',
      newhostingid: '',
      copiedText: false,
      loggedin: false,
      lastUser: '',
      visible: '',
      currentWPLogin: {},
      currentWPURL: '',
      password: '',
      message: '',
      users: 0,
      userData: {},
      timer: [0,0,0],
      searchTerm: '',
      blockerMenu: false,
      historyMenu: false,
      historyLoaded: false,
      history: null,
      userListMenu: false,
      userList: {},
      process: {
        running: true,
        path: ''
      },
      total: {
        imports: 0,
        logins: 0
      },
      processTime: ''
    } 
  },
  methods: {
    getSiteName(element) {
      let site = this.sites.find((site) => { if(site.hostingid == element.hostingid) { return true; }});
      return this.renderPath(site.path)[1]
    },
    renderPath(path) {
      //eslint-disable-next-line
      return path.match(new RegExp('\/([^/]*)\/([^/]*)\/$'));
    },
    selectText(nodeId) {
        const node = nodeId;

        if (document.body.createTextRange) {
            const range = document.body.createTextRange();
            range.moveToElementText(node);
            range.select();
            document.execCommand('copy');
            this.copiedText = true;
            setTimeout(() => {this.copiedText = false}, 2000);
        } else if (window.getSelection) {
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(node);
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand('copy');
            this.copiedText = true;
            setTimeout(() => {this.copiedText = false}, 2000);
        } else {
            console.warn("Could not select text in node: Unsupported browser.");
        }
    },
    findShop() {
      if(this.searchTerm.length >= 3) {
        document.querySelectorAll('.tablerow').forEach(item => item.classList.remove('bg-yellow-100'));
        this.sites.sort((firstEl) => {
          return firstEl.path.includes(this.searchTerm) ? -1 : 1;
        });
        document.querySelector('.tablerow').classList.add('bg-yellow-100');
      }
    },
    getHostingData(id) {
      axios.post('/api/auth/gethosting', {
        id: id
      })
      .then((res) => {
        this.currentWPURL = 'https://' + JSON.parse(res.data[0].access_data).domains.development.url + '/wp-login.php';
        this.currentWPLogin = JSON.parse(res.data[0].access_data).wordpress;
      })
      .catch(() => {
      })
    },
    signOut() {
      axios.post('/api/auth/signout', {
        username: this.userData.username
      })
      .then(() => {
          socket.emit('userdisconnected', this.userData.username);
          router.push({name: 'login', params: { logout: true }});
      })
      .catch(() => {
          socket.emit('userdisconnected', this.userData.username);
          router.push({name: 'login', params: { logout: true }});
      })
    },
    createSite() {
      const globalRegex = new RegExp('/[a-z].*/[a-z].*/', 'g');
      if(!globalRegex.test(this.newpath)) {
        this.message = 'the path is not valid'
      } else {
        axios.post('/api/auth/createSite', {
          path: this.newpath,
          auth: this.password,
          ip: this.newip,
          hostingid: this.newhostingid
        })
        .then((res) => {
          this.message = res.data.message;
          this.newpath = '';
          this.password = '';
          socket.emit('change');
        })
        .catch((error) => {
            console.log(false, error.response.data);
        })
      }
    },
    deleteSite(id) {
      let password = prompt("Please enter password");
      axios.post('/api/auth/deleteSite', {
        id: id,
        auth: password
      })
      .then(() => {
        socket.emit('change');
      })
      .catch((error) => {
        alert(error.response.data.message)
      })
    },
    updateSite(site) {
      axios.post('/api/auth/updateSite', {
        ip: site.ip,
        hostingid: site.hostingid,
        pathfull: site.path,
        blocked: site.blocked,
        blocker: site.blocker
      })
      .then(() => {
        socket.emit('change');
      })
      .catch((error) => {
          console.log(false, error.response.data);
      })
    },
    getSites() {
      axios.post('/api/auth/getAllSites')
      .then((res) => {
          this.sites = [];
          res.data.sites.forEach((site) => {
            this.sites.push({
              path: site.pathfull,
              ip: site.ip,
              import: false,
              hostingid: site.hostingid,
              blocked: site.blocked,
              blocker: site.blocker
            });
          });
      })
      .catch((error) => {
          console.log(false, error.response.data);
      })
    },
    getUserData() {
      axios.post('/api/user/')
      .then((res) => {
          this.userData = res.data.userdata[0];
      })
      .catch((error) => {
          console.log(false, error.response.data);
      })
    },
    getStats() {
      axios.post('/api/auth/getStats')
      .then((res) => {
          this.total.processes = res.data[0].totalimports;
          this.total.logins = res.data[0].totallogins;
          console.log(router.options.history.state.back);
          if(router.currentRoute._rawValue.params.login == 'true') {
            this.total.logins++;
            this.setStats();
            window.location.reload();
          }
      })
      .catch((error) => {
          console.log(false, error);
      })
    },
    setStats() {
      axios.post('/api/auth/setStats', {
        imports: this.total.processes,
        logins: this.total.logins
      })
      .then(() => {
          // console.log(true, res);
      })
      .catch((error) => {
          console.log(false, error.response.data);
      })
    },
    setHistory(hostingid, action) {
      axios.post('/api/auth/setHistory', {
        hostingid: hostingid,
        person: this.userData.firstname + ' ' + this.userData.surname,
        action: action
      })
      .then(() => {
          socket.emit('change');
      })
      .catch((error) => {
          console.log(false, error.response.data);
      })
    },
    getHistory() {
      axios.post('/api/auth/getHistory')
      .then((data) => {
          this.history = data.data;
          this.historyLoaded = true;
          // console.log(this.history);
      })
      .catch((error) => {
          console.log(false, error.response.data);
      })
    },
    checkToken() {
      axios.get('/api/test/user')
      .then(() => {
          this.loggedin = true;
          this.getUserList();
      })
      .catch((error) => {
          console.log(error.response.data);
          this.loggedin = false;
          socket.emit('userdisconnected', true);
          setTimeout(() => {
            router.push("/");
          }, 50);
      })
    },
    getUserList() {
      axios.get('/api/user/getall')
      .then((data) => {
          this.userList = Array.from(data.data.userList).filter(user => user.online);
      })
      .catch((error) => {
        console.log(error);
      })
    },
    cancelProcess() {
      socket.emit('abort', true);
    },
    startProcess(path, command, params, stdin, ip, hostingid) {
      let userOK = window.confirm('Are you sure about executing "' + command + '" in "' + path + '"?');
      if(!userOK) { return; }
      this.open = true;
      this.process.running = true;
      this.process.action = command == 'php install.php' ? 'install' : command == 'php reset.php' ? 'reset' : 'import';
      this.total.processes++;
      this.setHistory(hostingid, command);
      this.setStats();
      axios.get('/action/process?pathVar=' + path + '&command=' + command + '&params=' + params + '&stdin=' + stdin + '&ip=' + ip + '&user=' + this.userData.firstname + ' ' + this.userData.surname)
      .then(() => {
          this.process.running = false;
          this.sites.forEach((site) => {
            site.process = false;
          });
      })
      .catch((error) => {
          console.log(false, error.response.data);
          this.sites.forEach((site) => {
            site.process = false;
          });
      })
    },
    scrollToBottom() {
      this.$refs.logWindow.scrollTop = this.$refs.logWindow.scrollHeight;
    }
  },
  beforeRouteEnter(to, from) {
    console.log(from, to);
  },
  created () {
    this.checkToken();
    this.getStats();
  },
  mounted() {
    this.getSites();
    this.getUserData();
    this.getHistory();
    socket.on("sync", () => {
      this.getSites();
      this.getStats();
      this.getUserData();
      this.getHistory();
      this.getUserList();
    });
    window.addEventListener('beforeunload', () => {
      socket.emit('userdisconnected', this.userData.username);
    });
    socket.on("connect", () => {
      socket.emit('userdata', this.userData.username);
      console.log(socket.id);
    });
    socket.on('reconnect', function() {
      socket.emit('back', this.userData.username);
    });
    socket.on('disconnect', function() {
      socket.connect(() => {
        socket.emit('back', this.userData.username);
      });
    });
    socket.on("processtime", (time) => {
      this.processTime = time;
    });
    socket.on("connectionchange", () => {
      this.getUserList();
    });
    socket.on("log", (logText, status, path, command) => {
      this.process.running = true;
      this.process.running = status;
      this.process.path = path;
      this.process.action = command == 'php import.php' ? 'import' : command == 'php reset.php' ? 'reset' : 'install'; 
      if(this.$refs.logWindow) {
        if(this.$refs.logWindow.children.length > 50) {
          this.$refs.logWindow.firstElementChild.remove();
        }
        this.$refs.logWindow.innerHTML += '<p>' + logText + '</p>';
        this.scrollToBottom();
      }
    });
    socket.on("status", (status, path, command) => {
      this.process.running = status;
      this.process.path = path;
      this.process.action = command == 'php import.php' ? 'import' : command == 'php reset.php' ? 'reset' : 'install'; 
      if(status) {
        this.open = true;
        this.sites.forEach((site) => {
          if(site.path == path) {
            site.process = true;
          }
        });
      }
    });
    socket.on("lastuser", (user) => {
      this.lastUser = user;
    });
    socket.on("error", (message) => {
      this.open = true;
      this.$refs.logWindow.innerHTML += message;
    });
    socket.on("usercount", (count) => {
      this.users = count;
    });
    socket.on("processdone", () => {
      this.getHistory();
      this.sites.forEach((site) => {
        site.process = false;
      });
    });
    setInterval(() => {
      socket.emit('back', this.userData.username);
    }, 30000);
  },
}
</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
svg * {
  pointer-events: none !important;
}
.hidden.flex {
  display: flex;
}
.hidden.inline-block {
  display: inline-block;
}
.logwindow {
  overflow-y: auto;
}
.logwindow p {
  margin-bottom: 12.5px;
}
.lds-ring {
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ring.lds2-ring {
  width: 10px;
  height: 10px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 8px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring.lds2-ring div {
  width: 10px;
  height: 10px;
  margin: 0;
  border: 1px solid rgb(22, 101, 52);
  border-radius: 50%;
  border-color: rgb(22, 101, 52) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
button:focus {
  outline: none !important;
  box-shadow: none !important;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>