<template>
    <div class="mt-[20px] sm:mt-[60px] min-h-full flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <span class="text-xl ml-[15px] inline-flex font-bold text-[#0294FE]"><img class="mx-auto h-12 w-auto w-[225px]" src="@/assets/logo.svg" alt="Pressmind" /><span class="ml-1 translate-y-[-5px]">®</span></span>
        <p class="font-extrabold text-[.73rem] text-[#666] ml-[10px] mt-[-14px]">TRAVELSHOP FULLIMPORT MANAGER</p>
        <hr class="my-3 max-w-[400px] mx-auto" />
        <h2 class="mt-0 text-center text-sm font-extrabold text-gray-900">Sign in to your account</h2>
      </div>
      <div class="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form class="space-y-4" action="#" method="POST">
            <div>
              <label for="email" class="hidden text-sm font-medium text-gray-700"> Username </label>
              <div class="mt-1">
                <input v-model="this.userdata.username" id="email" name="email" placeholder="Username" type="email" autocomplete="email" required="" class="text-center appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#0478cc] focus:border-[#0478cc] sm:text-sm" />
              </div>
            </div>

            <div>
              <label for="password" class="hidden block text-sm font-medium text-gray-700"> Password </label>
              <div class="mt-0">
                <input @keydown.enter="this.amis ? this.checkLogin() : this.checkLoginExternal()" v-model="this.userdata.password" id="password" name="password" placeholder="Password" type="password" autocomplete="current-password" required="" class="text-center appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#0478cc] focus:border-[#0478cc] sm:text-sm" />
              </div>
            </div>

            <Checkbox checkboxName="AMIS Login" @checkboxEvent="this.amis = !this.amis" :checked="this.amis" :disabled="true">
              AMIS Login
            </Checkbox>

            <div>
              <div @click="this.amis ? this.checkLogin() : this.checkLoginExternal()" class="cursor-pointer w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#0294FE] hover:bg-[#0478cc] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0294FE]">Sign in</div>
            </div>
            <div v-if="this.failed" class="text-sm text-[darkred] text-center">Failed. Check your credentials.</div>
          </form>
      </div>
      <hr class="my-5 max-w-[400px] mx-auto" />
      <p class="text-center text-xs">created with love by <a class="underline" target="_blank" href="https://github.com/Me4th">Me4th</a></p>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import router from './router';
import Checkbox from './modules/Checkbox.vue';

export default {
  name: 'App',
  components: {
    Checkbox
  },
  data() {
    return {
      userdata: {
        username: '',
        password: ''
      },
      amis: false,
      failed: false
    } 
  },
  methods: {
    checkLoginExternal() {
      axios.post('/api/auth/signin', {
        username: this.userdata.username,
        password: this.userdata.password
      }).then(() => {
          router.push({name: 'dashboard', params: { login: true, username: this.userdata.username }});
        }).catch(error => console.log(error));
    },
    checkLogin() {
      axios.get('https://api-amis-team-braeutigam.pressmind.net/rest/CurrentUser', {
        auth: {
          username: this.userdata.username,
          password: this.userdata.password
        }
      }).then((resAMIS) => {
        console.log(resAMIS);
        axios.post('/api/user/update', resAMIS.data.result ).then(() => {
          router.push({name: 'dashboard', params: { login: true, username: this.userdata.username }});
        }).catch(error => console.log(error));
      }).catch((error) => {
        console.log(error);
          this.failed = true;
          setTimeout(() => {
            this.failed = false;
          }, 2500);
      });
    },
    checkToken() {
      axios.get('/api/test/user')
      .then(() => {
          router.push({name: 'dashboard', params: { login: true }});
      })
      .catch((error) => {
          console.log(false, error.response.data);
      }).finally(() => {

      });
    }
  },
  mounted() {
    this.checkToken();
    if(router.currentRoute._rawValue.params.logout) {
      setTimeout(() => {
        location.reload();
      }, 250);
    }
  }
}
</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>