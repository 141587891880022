<template>
    <div class="checkbox-wrap content-center">
        <div class="checkbox" :class="{ active : this.confirmed, disabled : this.disabled === 'true' }" @click="this.confirmed = !this.confirmed; this.$emit('checkboxEvent', [this.checkboxName, this.confirmed])"></div>
        <span class="leading-tight">
            <slot></slot>
        </span>
    </div>
</template>

<script>
export default {
  name: "CheckboxSlide",
  components: {
  },
  props: {
    checkboxName: String,
    checked: String,
    disabled: String
  },
  data() {
    return {
        confirmed: false
    };
  },
  computed: {
  },
  methods: {
  },
  mounted() {
  },
  watch:{
  } 
};
</script>

<style scoped lang="scss">
.checkbox-wrap {
    display: inline-flex;
    align-items: center;
    margin: 1rem auto;
    .checkbox {
        width: 40px;
        min-width: 40px;
        height: 20px;
        position: relative;
        background: #e74c3c;
        border-radius: 13px;
        transition: 200ms ease-in-out all;
        cursor: pointer;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        margin-right: .5rem;
        &:before {
            content: '';
            display: block;
            position: absolute;
            width:  16px;
            height: 16px;
            border-radius: 50%;
            left: 2px;
            top: 2px;
            background: #3b3b3b;
            transition: 200ms ease-in-out all;
            box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        }
        &.active {
            background: #2ecc71;
            &:before {
                transform: translateX(20px);
            }
        }
        &.disabled {
            background: #ccc;
            pointer-events: none;
        }
    }
    span {
        color: #333;
        line-height: 1;
        a {
            color: #fff;
            text-decoration: underline;
        }
    }
}
</style>