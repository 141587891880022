import { createWebHistory, createRouter } from "vue-router";
import LogIn from './LogIn.vue'
import Dashboard from './Dashboard.vue'

const routes = [
  {
    path: "/",
    name: "login",
    component: LogIn,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    params: {
      login: false
    }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;